/* .m-button,
.m-menu-builder-header-label,
.m-menu-builder-label,
.m-menu-builder-data,
.m-login-h1 {
  font-family: "Arial Bold", Arial, sans-serif;
} */

.m-btn-icon {
  color: rgb(158, 156, 156);
  font-size: xx-large;
}
.m-btn-icon:hover {
  color: #f68c21;
}
.m-btn-icon-trash:hover {
  color: #ef3f2e;
}
.m-button {
  color: #f68c21;
  background: white;
  width: 130px;
  border: solid;
  border-width: thin;
  border-radius: 6px;
  padding: 10px;
}
.m-button:hover:enabled {
  background: #f68c21;
  color: white;
}

.m-button:disabled,
.m-button[disabled] {
  border: 1px solid #999999;
  background: #cccccc;
  color: #666666;
}
.m-button-inverse {
  background: #f68c21;
  color: white;
  width: 130px;
  border-width: thin;
  border-radius: 6px;
  padding: 10px;
}

.m-buttons-right {
  display: flex;
  float: right;
}

.m-card-style {
  color: #d3d3d3;
  border: solid;
  border-width: thin;
  border-radius: 6px;
  padding: 10px 10px;
}

.m-menu-builder-header-label {
  color: #f68c21;
}
.m-menu-builder-label {
  color: #969696;
}
.m-menu-builder-data {
  color: black;
}

.ReactTable .rt-tbody .rt-tr:not(.-padRow):hover {
  border: 2px solid;
  border-color: #f68c21;
}

.m-logo-src {
  height: 40px;
  width: 160px;
  background: url("~assets/my-assets/logo_160x40.png");
}

.m-login-card {
  width: 50rem;
  height: 35rem;
  border-radius: 5%;
  margin: 10px auto auto auto;
  padding: 10px;
}

.m-login-h1 {
  font-size: xx-large;
}

.m-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  /* width: 55px; */

  line-height: 35px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.m-dropdown {
  margin-left: 10px;
  text-align: left;
  width: auto;
}
/* .m-menu-builder-template-selector-card {
  height: 2rem;
  align-items: center;
} */

@media only screen and (max-width: 800px) {
  .m-login-card {
    margin: 10px 30px 0 30px;
    height: 40rem;
  }
  .m-login-h1 {
    margin-top: 5px;
  }
}
